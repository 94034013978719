
import React from "react";
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import Layout from 'components/Templates/Base';
import "src/styles/global.css";

const styledTheme = {
  media: {
    max: `@media (max-width: 1200px)`,
    pc: `@media (max-width: 1024px)`,
    tab: `@media (max-width: 768px)`,
    tabSp: `@media (max-width: 640px)`,
    sp: `@media (max-width: 375px)`,
    min: `@media (max-width: 350px)`,
  },
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <StyledThemeProvider theme={styledTheme}>
      <Layout {...props}>
        {element}
      </Layout>
    </StyledThemeProvider>
  );
};
