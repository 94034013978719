import React, { ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import "modern-css-reset";
import 'aos/dist/aos.css';
import Header from 'components/LV3/Header';
import Footer from 'components/LV3/Footer';

const Wrap = styled.div`
  width: 100%;
  margin: auto;
  position: relative;
  background-color: #faf7f4;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    background-color: #efe9e6;
    z-index: 1;
    ${props => props.theme.media.tab} {
      width: 100px;
    }
  }
`;

const Contents = styled.div`
  overflow: hidden;
`;

type Props = {
  children: ReactNode;
};

const BaseTemplate: React.FC<Props> = props => {
  const { children } = props;

  let AOS: { refresh: () => void; };
  useEffect(() => {
    const AOS = require("aos");
    AOS.init({
      once: true,
    });
  }, []);

  useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  });

  return (
    <Wrap id="top">
      <Header />
      <Contents>{children}</Contents>
      <Footer {...props} />
    </Wrap>
  );
};

export default BaseTemplate;
