import React, { Fragment } from 'react';
import { Link } from "gatsby";
import styled from 'styled-components';

const Wrap = styled(Link) <{ center: boolean, isRight: boolean, isWhite: boolean, boarderBlack: boolean }>`
  position: relative;
  z-index: 1;
  display: block;
  cursor: pointer;
  ${props => props.center ? 'margin: auto;' : 'margin-left: 0;'}
  ${props => props.isRight && 'margin: auto 0 0 auto;'}
  ${props => props.isWhite && 'color: #fff !important;'}
  width: fit-content;
  height: 34px;
  font-family: 'Marcellus', serif;
  font-size: 1.6rem;
  letter-spacing: 0.02em;
  &:hover {
    opacity: 0.8;
    &:before {
      animation-name: ${props => props.boarderBlack ? 'none' : 'arrow-view'};
      animation-duration: 1s;
      animation-fill-mode: forwards;
    }
    @keyframes arrow-view {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .link-circle {
      animation: link-circle-open .5s ease-out forwards;
      animation-duration:1s;
      animation-fill-mode:forwards;
      animation: link-circle-open .5s ease-out forwards;
      @keyframes link-circle-open{
        0%{
          transform: scale(0);
        }
        100%{
          transform: scale(1);
        }
      }
    }
  }
  ${props => props.theme.media.tab} {
    margin-top: 20px;
  }
  &::before {
    content: "";
    position: absolute;
    top: calc(50% + 2px);
    right: 34px;
    width: 21px;
    height: 1px;
    background-color: ${props => props.boarderBlack ? '#000' : '#fff'};
  }
  &::after {
    content: "";
    position: absolute;
    top: calc(50% - 4px);
    right: 17px;
    width: 1px;
    height: 8px;
    background-color: #000;
    transform: rotate(-45deg);
  }
`;

const LinkBar = styled.span`
  &::before {
    content: "";
    position: absolute;
    top: calc(50% + 2px);
    right: 15px;
    width: 19px;
    height: 1px;
    background-color: #000;
    z-index: 1;
  }
`;

const LinkCircle = styled.div<{ boarderBlack: boolean }>`
  vertical-align: -10px;
  display: inline-block;
  position: relative;
  margin-left: 40px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  ${props => props.boarderBlack
    ? 'border: 1px solid #000;'
    :
    `
      border: 1px solid #fff;
      background-color: #fff;
      `
  }
`;

type Props = {
  link: string;
  text: string;
  center?: boolean;
  right?: boolean;
  white?: boolean;
  boarderBlack?: boolean;
  isBlankLink?: boolean;
};

const getContent = (text: string, boarderBlack: boolean) => {
  return (
    <Fragment>
      {text}
      <LinkBar />
      <LinkCircle className="link-circle" boarderBlack={boarderBlack} />
    </Fragment>
  );
};

const TextCircleButton: React.FC<Props> = props => {
  const isBorderBlack = !!props.boarderBlack;
  const isCenter = !!props.center;
  const isRight = !!props.right;
  const isWhite = !!props.white;
  return (
    <Fragment>
      {props.isBlankLink ? (
        <Wrap as="a" href={props.link} center={isCenter} isRight={isRight} isWhite={isWhite} boarderBlack={isBorderBlack} target="_blank" rel="noopener noreferrer">
          {getContent(props.text, isBorderBlack)}
        </Wrap>
      ) : (
        <Wrap to={props.link} center={isCenter} isRight={isRight} isWhite={isWhite} boarderBlack={isBorderBlack}>
          {getContent(props.text, isBorderBlack)}
        </Wrap>
      )}
    </Fragment>
  );
};
export default TextCircleButton;
