import React, { useEffect, useState } from 'react';
import { Link } from "gatsby";
import styled from 'styled-components';
import { Path } from 'components/variables/path';
import Image from 'components/LV1/image';
import MenuList from 'components/LV2/Lists/MenuList';
import BgMenuSp from "src/images/common/bg-menu-sp.jpg";

const MenuListData = [
  { no: 1, text: 'ABOUT', textJp: 'わたしたちについて', link: Path.about, delay: '0' },
  { no: 2, text: 'SERVICE', textJp: 'サービス', link: Path.service, delay: '200' },
  { no: 3, text: 'RECRUIT', textJp: '採用情報', link: 'https://www.wantedly.com/companies/coclabo', isBlank: true, delay: '600' },
  { no: 4, text: 'PRIVACY', textJp: 'プライバシーポリシー', link: Path.privacy, delay: '800' },
  { no: 5, text: 'CONTACT', textJp: 'お問い合わせ', link: Path.contact, isBlank: true, delay: '1000' },
];

const Wrap = styled.div<{ isTop: boolean }>`
  position:fixed;
  z-index: 10;
  top:0;
  left:0;
  width:100%;
  height: 90px;
  padding: 0 50px;
  display: flex;
  justify-content: space-between;
  transition: all .8s;
  background-color: ${props => props.isTop ? 'rgba(255, 255, 255, 0)' : 'rgba(255, 255, 255, 0.6)'};
  ${props => props.theme.media.max} {
    padding: 0 15px;
  }
  ${props => props.theme.media.tab} {
    height: 60px;
    padding: 0 15px;
  }
`;

const WrapImage = styled.div`
  width: 100%;
  max-width: 200px;
  padding: 21px 0;
  z-index: 100;
  ${props => props.theme.media.tab} {
    max-width: 122px;
    padding: 15px 0;
  }
`;

const WrapMenu = styled.div`
  width: fit-content;
`;

const HumbergerButton = styled.button`
  position: fixed;
  display: none;
  width: calc(64px + 80px);
  height: 120px;
  padding: 0;
  margin: 0;
  z-index: 11;
  top: 0px;
  right: 0px;
  box-sizing: content-box;
  transition: right .9s ease-in-out;
  transform: translate3d(0, 0, 0);
  border: 0;
  outline: 0;
  background-color: transparent;
  ${props => props.theme.media.tab} {
    display: block;
    width: 64px;
    height: 60px;
  }
`;

const HambergerLink = styled.a`
  display: block;
  position: relative;
  width: 100%;
  height: 120px;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  transition: all .4s;
  box-sizing: border-box;
  ${props => props.theme.media.tab} {
    height: 60px;
  }
`;

const HambergerBar = styled.span<{ isOpen: boolean }>`
  display: table-cell;
  position: absolute;
  left: calc(50% - 32px);
  width: 64px;
  height: 1px;
  background-color: #000000;
  text-align: center;
  transition: all .4s;
  box-sizing: border-box;
  &:nth-of-type(1) {
    top: calc(50% - 8px);
  }
  &:nth-of-type(2) {
    top: calc(50% + 8px);
  }
  ${props => props.theme.media.tab} {
    width: 32px;
    left: calc(50% - 16px);
    &:nth-of-type(1) {
      top: calc(50% - 4px);
    }
    &:nth-of-type(2) {
      top: calc(50% + 4px);
    }
  }
  ${({ isOpen }) =>
    isOpen &&
    `
      background-color: #fff;
      &:nth-of-type(1) {
        top: calc(50% - 8px);
        transform: translateY(8px) rotate(-30deg);
      }
      &:nth-of-type(2) {
        top: calc(50% + 8px);
        transform: translateY(-9px) rotate(30deg);
      }
    `}
`;

const GnavWrap = styled.nav<{ isOpen: boolean }>`
  visibility: hidden;
  opacity: 0;
  position: fixed;
  overflow-x: scroll;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-image: url(${BgMenuSp});
  background-position: center;
  transition: .5s;
  .gnav-inner {
    flex-wrap: wrap;
    display: flex;
    position: absolute;
    top: calc(50% - 190px);
    width: 100%;
    justify-content: center;
    text-align: center;
    ul {
      padding: 0;
    }
  }
  ${({ isOpen }) =>
    isOpen &&
    `
      visibility: visible;
      opacity: 1;
    `}
`;

const GnavItem = styled.li<{ isOpen: boolean, delay: string }>`
  margin: 80px 0;
  list-style: none;
  opacity: 0;
  ${({ isOpen, delay }) =>
    isOpen &&
    `
    animation: fadeup 1s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
    animation-delay: ${delay}ms;
  `}
  @keyframes fadeup {
    0% {
      transform: translateY(30px);
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  a {
    display:block;
    width:100%;
    color: #fff;
    font-family: 'Marcellus', serif;
    font-size: 4.4rem;
    letter-spacing: 0.1em;
    text-align: center;
    text-decoration: none;
    transition: .5s;
    span {
      font-family: 'Noto Sans JP', 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 2.2rem;
      letter-spacing: 0.2em;
      color: #a5a5a5;
      padding-left: 10px;
    }
  }
  a:hover {
    color: #666;
  }
  ${props => props.theme.media.tab} {
    margin: 60px 0;
    a {
      font-size: 2.2rem;
      span {
        font-size: 1.6rem;
      }
    }
  }
  ${props => props.theme.media.sp} {
    margin: 40px 0;
    a {
      font-size: 2rem;
      span {
        font-size: 1.4rem;
      }
    }
  }
`;

const CopyRight = styled.div`
  width: 100%;
  height: fit-content;
  bottom: 10px;
  margin: auto;
  color: #4c4c4c;
  letter-spacing: 0.1em;
`;

const scrollTop = (): number => {
  return Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
};

const Header: React.FC = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isTop, setIsTop] = useState<boolean>(true);

  var timer: NodeJS.Timeout;
  var FPS = 1000 / 20;

  function onScroll() {
    clearTimeout(timer);
    timer = setTimeout(function () {
      const position = scrollTop();
      if (position >= 90) {
        setIsTop(false);
      } else {
        setIsTop(true);
      }
    }, FPS);
  }

  useEffect(() => {
    document.addEventListener('scroll', onScroll, { passive: true });
    return (): void => document.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <Wrap isTop={isTop}>
      <WrapImage>
        <Link to={Path.top} onClick={() => setIsOpen(false)}>
          <Image filename={isOpen ? 'common/logo-menu-sp.png' : 'common/logo-landscape.png'} alt="logo-header" />
        </Link>
      </WrapImage>
      <WrapMenu>
        <MenuList list={MenuListData} />
        <HumbergerButton onClick={() => setIsOpen(!isOpen)}>
          <HambergerLink>
            <HambergerBar isOpen={isOpen} />
            <HambergerBar isOpen={isOpen} />
          </HambergerLink>
        </HumbergerButton>
        <GnavWrap isOpen={isOpen}>
          <div className="gnav-inner">
            <ul>
              {MenuListData.map((item, index) => (
                <GnavItem key={item.no} isOpen={isOpen} delay={item.delay}>
                  {item.isBlank ? (
                    <a href={item.link} target="_blank" rel="noopener noreferrer">{item.text}<span>/&nbsp;{item.textJp}</span></a>
                  ) : (
                    <Link to={item.link} onClick={() => setIsOpen(false)}>{item.text}<span>/&nbsp;{item.textJp}</span></Link>
                  )}
                </GnavItem>
              ))}
            </ul>
            <CopyRight>Copyright © Coc-Labo, Inc. All Rights Reserved.</CopyRight>
          </div>
        </GnavWrap>
      </WrapMenu>
    </Wrap >
  );
};
export default Header;
