import React from 'react';
import { Link } from "gatsby";
import scrollTo from 'gatsby-plugin-smoothscroll';
import styled from 'styled-components';
import { Path } from 'components/variables/path';
import Image from 'components/LV1/image';
import TextCircleButton from 'components/LV1/TextCircleButton';
import SlideText from 'components/LV1/AosSlideText';
import MenuList from 'components/LV2/Lists/MenuList';
import BgJoinUs from "src/images/common/footer-joinus-bg.jpg";
import BgJoinUsSp from "src/images/common/footer-joinus-bg-sp.jpg";

const MenuListData = [
  { text: 'ABOUT', link: Path.about, },
  { text: 'PRIVACY', link: Path.privacy, },
  { text: 'CONTACT', link: Path.contact, isBlank: true },
];

const Wrap = styled.div`
  position: relative;
  z-index: 1;
`;

const JoinUsWrap = styled.div`
  padding: 126px 0;
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 1.6rem;
  letter-spacing: 0.2em;
  background-image: url(${BgJoinUs});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  ${props => props.theme.media.pc} {
    display: block;
    padding: 64px 20px;
    text-align: center;
    background-image: url(${BgJoinUsSp});
  }
`;

const JoinUsTitle = styled.div`
  p {
    font-size: 5.4rem;
    letter-spacing: 0.1em;
    font-family: 'Marcellus', serif;
    span {
      font-size: 1.4rem;
      letter-spacing: 0.2em;
    }
    ${props => props.theme.media.tab} {
      font-size: 4.4rem;
      span {
        font-size: 1.2rem;
      }
    }
    ${props => props.theme.media.sp} {
      text-align: left;
    }
  }
`;

const JoinUsDescription = styled.div`
  margin: auto 6% auto 74px;
  font-size: 1.6rem;
  letter-spacing: 0.2em;
  line-height: 2;
  ${props => props.theme.media.pc} {
    margin: 30px auto 20px;
  }
  ${props => props.theme.media.tab} {
    font-size: 1.4rem;
  }
  ${props => props.theme.media.sp} {
    margin: 25px auto 10px;
    text-align: left;
  }
  ${props => props.theme.media.min} {
    font-size: 1.2rem;
  }
`;

const EntryButtonWrap = styled.div`
  padding: 17px 0;
`;

const BarWrap = styled.div`
  width:100%;
  height: 140px;
  background-color: #000;
  padding: 0 0 0 50px;
  display: flex;
  justify-content: space-between;
  ${props => props.theme.media.max} {
    padding: 0 0 0 15px;
  }
  ${props => props.theme.media.tab} {
    height: 70px;
    padding: 0 0 0 15px;
  }
`;

const WrapImage = styled.div`
  width: 200px;
  padding: 46px 0;
  margin-right: 50px;
  ${props => props.theme.media.tab} {
    max-width: 122px;
    margin-right: 0;
    padding: 23px 0;
  }
`;

const Left = styled.div`
  display: flex;
`;

const Right = styled.div`
  display: flex;
`;

const CopyRight = styled.div`
  width: calc(100% - 140px);
  margin: 62px 25px;
  color: #4c4c4c;
  letter-spacing: 0.1em;
  ${props => props.theme.media.pc} {
    display: none;
  }
`;

const BackTop = styled.div`
  position:relative;
  width: 140px;
  background-color: #101010;
  cursor: pointer;
  transition: all .3s;
  &:before {
    content: '';
    position: absolute;
    top: calc(50% - 3px);
    right: calc(50% - 3px);
    border-left-color: #cbcbcb;
    width: 12px;
    height: 12px;
    border-top: 2px solid #cbcbcb;
    border-right: 2px solid #cbcbcb;
    transform: rotate(-45deg);
    filter: alpha(opacity=0);
  }
  &:hover {
    opacity: 0.6;
    transition: all .3s;
  }
  ${props => props.theme.media.tab} {
    width: 70px;
  }
`;

const Footer: React.FC = () => {
  return (
    <Wrap>
      <JoinUsWrap>
        <JoinUsTitle>
          <p><SlideText>Join us<span>/&nbsp; 採用情報</span></SlideText></p>
        </JoinUsTitle>
        <JoinUsDescription>
          <SlideText duration="1400">
            コクラボでは通年採用を行っております。
            <br />
            お気軽にお問い合わせください。
          </SlideText>
        </JoinUsDescription>
        <EntryButtonWrap>
          <SlideText duration="1600">
            <TextCircleButton
              link="https://www.wantedly.com/companies/coclabo"
              text="ENTRY"
              center
              isBlankLink
              white
            />
          </SlideText>
        </EntryButtonWrap>
      </JoinUsWrap>
      <BarWrap>
        <Left>
          <WrapImage>
            <Link to={Path.top}>
              <Image filename="common/logo-landscape-white.png" alt="logo-landscape-white" />
            </Link>
          </WrapImage>
          <MenuList list={MenuListData} isFooter />
        </Left>
        <Right>
          <CopyRight>Copyright © Coc-Labo, Inc. All Rights Reserved.</CopyRight>
          <BackTop onClick={() => scrollTo('#top')}>
            backtop
          </BackTop>
        </Right>
      </BarWrap>
    </Wrap>
  );
};
export default Footer;
