import React, { ReactNode } from 'react';

type Props = {
  delay?: string;
  children: ReactNode;
};

const Aos: React.FC<Props> = (props: Props) => {
  const { children, delay } = props;
  return (
    <div
      data-aos="fade-up"
      // data-aos-offset="200"
      data-aos-delay={delay ? delay : "50"}
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      // data-aos-mirror="true"
      data-aos-once="false"
      data-aos-anchor-placement="top-bottom"
    >
      {children}
    </div>
  )
}

export default Aos;