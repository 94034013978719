import React from 'react';
import { Link } from "gatsby";
import styled from 'styled-components';
import Aos from 'components/LV1/Aos';

const List = styled.ul<{ isFooter: boolean }>`
  display: flex;
  li {
    list-style: none;
    &:not(:first-child) {
      margin-left: 34px;
    }
    a {
      position: relative;
      width: 100%;
      display: block;
      font-size: 1.4rem;
      line-height: 90px;
      letter-spacing: 0.12em;
      font-family: 'Marcellus', serif;
      color: ${props => props.isFooter ? '#fff' : '#000'};
      ${props => props.isFooter ? 'padding: 25px 0px;' : ''};
      text-decoration: none;
      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        // top: calc(50% + 12px);
        top: 50%;
        border-bottom: solid 1px ${props => props.isFooter ? '#fff' : '#000'};
        transition: all .4s ease;
        -webkit-transition: all .4s ease;
        width: 0;
      }
      &::before {
        left: 50%;
      }
      &::after {
        right: 50%;
      }
      &:hover::before,
      &:hover::after {
        width: 50%;
      }
    }
  }
  ${props => props.theme.media.tab} {
    display: none;
  }
`;

type Props = {
  list: {
    text: string,
    link: string,
    isBlank?: boolean,
  }[];
  isFooter?: boolean;
};

const MenuList: React.FC<Props> = (props: Props) => {
  return (
    <List isFooter={!!props.isFooter}>
      {props.list.map((item, index) => (
        <li key={index}>
          {props.isFooter ? (
            <>
              {item.isBlank ? (
                <Link to={item.link} target="_blank" rel="noopener noreferrer">
                  {item.text}
                </Link>
              ) : (
                <Link to={item.link}>
                  {item.text}
                </Link>
              )}
            </>
          ) : (
            <Aos delay={String(150 + (150 * index))}>
              {item.isBlank ? (
                <Link to={item.link} target="_blank" rel="noopener noreferrer">
                  {item.text}
                </Link>
              ) : (
                <Link to={item.link}>
                  {item.text}
                </Link>
              )}
            </Aos>
          )}
        </li>
      ))}
    </List>
  );
};
export default MenuList;
