import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Wrap = styled.div<{ delay: string, duration: string, inline: string, lineHeight: string }>`
  display: ${props => props.inline};
  width: fit-content;
  line-height: ${props => props.lineHeight};
  clip-path: inset(0 100% 0 0);
  transition: ease-in-out;
  transition-delay: ${props => `${props.delay}`}ms !important;
  transition-duration: ${props => `${props.duration}`}ms  !important;
  transition-property: clip-path;
  &.aos-animate {
    clip-path: inset(0);
  }
`;

type Props = {
  delay?: string;
  duration?: string;
  isInline?: boolean;
  lineHeight?: string;
  children: ReactNode;
};

const AosSlideText: React.FC<Props> = props => {
  const { delay, duration, isInline, lineHeight, children } = props;
  return (
    <Wrap
      delay={delay || "0"}
      duration={duration || "1200"}
      inline={isInline ? 'inline-block' : 'block'}
      lineHeight={lineHeight || 'normal'}
      data-aos=""
      data-aos-once="false"
      data-aos-anchor-placement="top-bottom"
    >
      {children}
    </Wrap>
  )
}

export default AosSlideText;